@import '../../style/themes/index';
@import '../../style/mixins/index';

@empty-prefix-cls: ~'@{ant-prefix}-empty';
@empty-img-prefix-cls: ~'@{ant-prefix}-empty-img';

.@{empty-prefix-cls} {
  margin: 0 8px;
  font-size: @empty-font-size;
  line-height: @line-height-base;
  text-align: center;

  &-image {
    height: 100px;
    margin-bottom: 8px;

    img {
      height: 100%;
    }

    svg {
      height: 100%;
      margin: auto;
    }
  }

  &-description {
    margin: 0;
  }

  &-footer {
    margin-top: 16px;
  }

  // antd internal empty style
  &-normal {
    margin: 32px 0;
    color: @disabled-color;

    .@{empty-prefix-cls}-image {
      height: 40px;
    }
  }

  &-small {
    margin: 8px 0;
    color: @disabled-color;

    .@{empty-prefix-cls}-image {
      height: 35px;
    }
  }
}

.@{empty-img-prefix-cls}-default {
  // not support the definition because the less variables have no meaning
  & when (@theme = dark) {
    &-ellipse {
      fill-opacity: 0.08;
      fill: @white;
    }
    &-path {
      &-1 {
        fill: #262626;
      }
      &-2 {
        fill: url(#linearGradient-1);
      }
      &-3 {
        fill: #595959;
      }
      &-4 {
        fill: #434343;
      }
      &-5 {
        fill: #595959;
      }
    }
    &-g {
      fill: #434343;
    }
  }
  & when not (@theme = dark) {
    &-ellipse {
      fill-opacity: 0.8;
      fill: #f5f5f5;
    }
    &-path {
      &-1 {
        fill: #aeb8c2;
      }
      &-2 {
        fill: url(#linearGradient-1);
      }
      &-3 {
        fill: #f5f5f7;
      }
      &-4 {
        fill: #dce0e6;
      }
      &-5 {
        fill: #dce0e6;
      }
    }
    &-g {
      fill: @white;
    }
  }
}

.@{empty-img-prefix-cls}-simple {
  // not support the definition because the less variables have no meaning
  & when (@theme = dark) {
    &-ellipse {
      fill: @white;
      fill-opacity: 0.08;
    }
    &-g {
      stroke: #434343;
    }
    &-path {
      fill: #262626;
      stroke: #434343;
    }
  }
  & when not (@theme = dark) {
    &-ellipse {
      fill: #f5f5f5;
    }
    &-g {
      stroke: #d9d9d9;
    }
    &-path {
      fill: #fafafa;
    }
  }
}

@import './rtl';

@body-background: #fff;@font-size-base: 15px;@font-size-sm: 13px;@font-size-lg: 17px;@font-family: Source Sans Pro, sans-serif;@code-family: Source Sans Pro, sans-serif;@border-color-base: #e4e9f0;@background-color-light: #f2f4f8;@background-color-base: #f2f4f8;@primary-color: #1b55e3;@info-color: #0887c9;@success-color: #46be8a;@error-color: #fb434a;@highlight-color: #fb434a;@warning-color: #f39834;@normal-color: #e4e9f0;@input-color: #161537;@input-padding-horizontal: 13px;@input-padding-vertical-base: 7px;@input-padding-vertical-sm: 11px;@input-padding-vertical-lg: 11px;@input-border-color: #e4e9f0;@input-hover-border-color: #0190fe;@shadow-color: rgba(0,0,0,.15);@box-shadow-base: 0 10px 35px -5px rgba(0,0,0,.15);@shadow-1-up: 0 -10px 35px -5px rgba(0,0,0,.15);@shadow-1-down: 0 10px 35px -5px rgba(0,0,0,.15);@shadow-1-left: -10px 10px 35px -5px rgba(0,0,0,.15);@shadow-1-right: 10px 0 35px -5px rgba(0,0,0,.15);@shadow-2: 0 0px 35px -5px rgba(0,0,0,.15);@table-header-bg: #f2f4f8;@table-header-sort-bg: #f2f4f8;@table-row-hover-bg: #e4e9f0;@table-padding-vertical: 12px;@table-padding-horizontal: 8px;@modal-mask-bg: rgba(15, 12, 41, 0.5);@layout-body-background: #fff;@layout-header-background: transparent;@layout-sider-background: transparent;